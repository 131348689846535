export const reportTypeDesc = {
    0: $localize`:@@report_type_Undefined:Undefined `, // ReportType.Undefined 
    1: $localize`:@@report_type_Default:Default`, // ReportType.Default 
    2: $localize`:@@report_type_AlertTrackerFleet:Alert Summary`, // ReportType.AlertTrackerFleet 
    3: $localize`:@@report_type_AlertTracker:Alert Tracker`, // ReportType.AlertTracker 
    4: $localize`:@@report_type_AlertLocation:Alert Location`, // ReportType.AlertLocation 
    5: $localize`:@@report_type_AlertTrip:Alert Trip`, // ReportType.AlertTrip 
    6: $localize`:@@report_type_FleetSpeeding:Fleet Speeding`, // ReportType.FleetSpeeding 
    7: $localize`:@@report_type_AlertStatus:Alert Status`, // ReportType.AlertStatus 
    8: $localize`:@@report_type_VehicleIdleTime:Vehicle Idle Time`, // ReportType.VehicleIdleTime 
    9: $localize`:@@report_type_VehicleMileage:Vehicle Mileage`, // ReportType.VehicleMileage 
    10: $localize`:@@report_type_VehicleEngineHours:Vehicle Engine Hours`, // ReportType.VehicleEngineHours 
    11: $localize`:@@report_type_VehicleEquipmentTimecard:Vehicle Equipment Timecard`, // ReportType.VehicleEquipmentTimecard 
    12: $localize`:@@report_type_VehicleDoorHarnessSummary:Vehicle Door Harness Summary`, // ReportType.VehicleDoorHarnessSummary 
    13: $localize`:@@report_type_TripTransfersByVehicle:Transfers By Vehicle`, // ReportType.TripTransfersByVehicle 
    14: $localize`:@@report_type_TrackerStateSummary:Tracker State Summary`, // ReportType.TrackerStateSummary 
    15: $localize`:@@report_type_FleetCurrentStatus:Fleet Current Status `, // ReportType.FleetCurrentStatus 
    16: $localize`:@@report_type_FleetCurrentStatusAggregate:Current Status (All DBs)`, // ReportType.FleetCurrentStatusAggregate 
    17: $localize`:@@report_type_FleetEquipmentTimeCard:Equipment Time Card`, // ReportType.FleetEquipmentTimeCard 
    18: $localize`:@@report_type_FleetIdleTime:Idle Time Summary`, // ReportType.FleetIdleTime 
    19: $localize`:@@report_type_FleetMileage:Mileage`, // ReportType.FleetMileage 
    20: $localize`:@@report_type_FleetEngineHours:Fleet Engine Hours `, // ReportType.FleetEngineHours 
    21: $localize`:@@report_type_FleetLocation:Fleet Location`, // ReportType.FleetLocation 
    22: $localize`:@@report_type_TripStatus:Trip Status`, // ReportType.TripStatus 
    23: $localize`:@@report_type_EndOfTrip:End of Trip`, // ReportType.EndOfTrip 
    24: $localize`:@@report_type_TripEndSummary:Trip End Summary`, // ReportType.TripEndSummary 
    25: $localize`:@@report_type_Landing:Landing`, // ReportType.Landing 
    26: $localize`:@@report_type_SensorReport:Sensor Report`, // ReportType.SensorReport 
    27: $localize`:@@report_type_TabPulledSummary:Tab Pulled Summary`, // ReportType.TabPulledSummary 
    28: $localize`:@@report_type_TabPulledDetailReport:Tab Pulled Detail Report`, // ReportType.TabPulledDetailReport 
    29: $localize`:@@report_type_TrackerStateReport:Tracker State Report`, // ReportType.TrackerStateReport 
    30: $localize`:@@report_type_PrintPackingListReport:Print Packing List Report`, // ReportType.PrintPackingListReport 
    31: $localize`:@@report_type_ComprehensiveTripReport:Comprehensive Trip Report`, // ReportType.ComprehensiveTripReport 
    32: $localize`:@@report_type_CustomVehiclesReport:Custom Vehicles Report`, // ReportType.CustomVehiclesReport 
    33: $localize`:@@report_type_EDILoadStatusReport:EDI Load Status Report`, // ReportType.EDILoadStatusReport 
    34: $localize`:@@report_type_EDILoadNotificationReport:EDI Load Notification Report`, // ReportType.EDILoadNotificationReport 
    35: $localize`:@@report_type_CumulativeTrackerStateReport:Cumulative Tracker State Report`, // ReportType.CumulativeTrackerStateReport 
    36: $localize`:@@report_type_SensorReportWithTempLocation:Sensor Report With Temp Location`, // ReportType.SensorReportWithTempLocation 
    37: $localize`:@@report_type_TrackerAuditReport:Tracker Audit Report`, // ReportType.TrackerAuditReport 
    38: $localize`:@@report_type_TripStopSummaryReport:Trip Stop Summary Report`, // ReportType.TripStopSummaryReport 
    39: $localize`:@@report_type_ShipmentDetailsReport:Shipment Details Report`, // ReportType.ShipmentDetailsReport 
    40: $localize`:@@report_type_CustomShipmentsReport:Custom Shipments Report`, // ReportType.CustomShipmentsReport 
    41: $localize`:@@report_type_InProgressShipment:In Progress Shipment`, // ReportType.InProgressShipment 
    42: $localize`:@@report_type_LocationInventoryReport:Location Inventory Report`, // ReportType.LocationInventoryReport 
    43: $localize`:@@report_type_GlobalLocationInventoryReport:Global Location Inventory Report`, // ReportType.GlobalLocationInventoryReport 
    44: $localize`:@@report_type_TrackerSubscription:Tracker Subscription`, // ReportType.TrackerSubscription 
    45: $localize`:@@report_type_TripStopSummaryExtendedReport:Trip Stop Summary Extended Report`, // ReportType.TripStopSummaryExtendedReport 
    46: $localize`:@@report_type_InboundOutboundReport:Inbound Outbound Report`, // ReportType.InboundOutboundReport 
    48: $localize`:@@report_type_TripStopThresholdSummaryReport:Trip Stop Threshold Summary Report`, // ReportType.TripStopThresholdSummaryReport 
}