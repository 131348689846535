import {CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit, TemplateRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {
    NgApexchartsModule,
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip
} from "ng-apexcharts";
import {forkJoin, of, Subject, takeUntil} from 'rxjs';
import {DashboardService} from "./dashboard.service";
import {MatTableModule} from "@angular/material/table";
import {MetricsHistoryContainer} from "../../core/models/metrics-history.model";
import {RecyclerMetrics} from "../../core/models/recycler-metrics.model";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
    LocationBreakdownTableComponent
} from "./@core/components/location-breakdown-table/location-breakdown-table.component";
import {environment} from "../../../environments/environment";
import {RecyclerLocation} from "../../core/models/recycler-location.model";
import {RecyclerLocationService} from "../../core/services/recycler-location.service";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {PlatformMediaWatcherService} from "../../../@platform/services/media-watcher";
import {
    CustomDateRangeDialogService
} from "./@core/components/custom-date-range-dialog/custom-date-range-dialog.service";
import {OversightService} from "../../core/services/oversight.service";
import {SummaryMetricCards} from "./@core/components/summary-metric-cards/summary-metric-cards.component";
import {  BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModule, NgbModal   } from '@ng-bootstrap/ng-bootstrap';

export interface reportPeriod {
    id: number;
    period: string;
}

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
};

@Component({
    selector: 'customer-recycling-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [MatButtonModule, MatIconModule, MatMenuModule, MatButtonToggleModule, NgApexchartsModule,
        MatTooltipModule, NgFor, DecimalPipe, MatTableModule, NgClass, CurrencyPipe, NgIf, NgStyle, MatProgressBarModule, MatProgressSpinnerModule
        , MatInputModule, FormsModule, MatSelectModule, DatePipe, SummaryMetricCards, LocationBreakdownTableComponent, NgbModule ]
})
export class CustomerRecyclingDashboardComponent implements OnInit, OnDestroy {
    @Input() customerId: string;
    @Input() title: string;
    @Input() showMatSelect: boolean = false;
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    public isLoading: boolean = false;
    public isScreenSmall: boolean;
    public isFoodServiceModel: boolean;
    public recyclerMetrics: RecyclerMetrics
    public recyclerLocations: RecyclerLocation[] = [];

    public recyclerLocationSupplierMetricsTableData: any;
    public selectedReportPeriodId: number = 1;
    public reportPeriod: reportPeriod = {id: 1, period: 'Year to Date'};
    public reportPeriods: reportPeriod[] = [
        {id: 1, period: 'Year to Date'},
        {id: 2, period: 'Last 30 Days'},
        {id: 3, period: 'Last 3 Months'},
        {id: 4, period: 'Last 9 Months'},
        {id: 5, period: 'Custom Range'}];
    public startDate: string;
    public endDate: string;
    public appVersion: string = environment.appVersion;
    public metricsList: any[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    modalRef?: NgbModule;

    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _customDateRangeDialogService: CustomDateRangeDialogService,
                private _dashboardService: DashboardService,
                private _platformMediaWatcherService: PlatformMediaWatcherService,
                private _recyclerLocationService: RecyclerLocationService,
                private _oversightService: OversightService,
                private _router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // On initialization of the component, this function will:
    // 1. Listen for data updates from the '_dashboardService'.
    // 2. When data is updated, it assigns the new data to the 'this.data' property and then prepares the chart data.
    // 3. It also attaches SVG fill fixes to all ApexCharts to address color issues.
    // -----------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        // Subscribe to media changes
        this._platformMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({matchingAliases}) => {
            this.isScreenSmall = !matchingAliases.includes('md');
            this._changeDetectorRef.markForCheck();
        });

        // Get the oversight customer id from local storage
        const oversightCustomerId = localStorage.getItem('emr-oversight-customer');
        if (!oversightCustomerId) {
            this._router.navigate(['/customer']).then();
            return;
        }

        // Get the food service model flag first then pass it to the metrics history and recycler metrics
        this._oversightService.getFoodServiceModelFlag(+oversightCustomerId).subscribe((isFoodServiceModel: boolean) => {
            this.isFoodServiceModel = isFoodServiceModel;
            // Get the metrics history, recycler metrics, and recycler location metrics
            this._dashboardService.getMetricsHistory(isFoodServiceModel, +oversightCustomerId).subscribe();
            this._dashboardService.getRecyclerMetrics(isFoodServiceModel, +oversightCustomerId).subscribe();
            this._recyclerLocationService.getRecyclerLocationMetrics(isFoodServiceModel, +oversightCustomerId).subscribe();
        });

        // Subscribe to the metrics history and recycler metrics
        this._dashboardService.metricsHistory$.pipe(takeUntil(this._unsubscribeAll)).subscribe((metricsHistory: MetricsHistoryContainer) => {
            this._prepareChartData(metricsHistory);
            this._changeDetectorRef.markForCheck();
            this.isLoading = false;
        });

        // Subscribe to the recycler metrics
        this._dashboardService.recyclerMetrics$.pipe(takeUntil(this._unsubscribeAll)).subscribe((recyclerMetrics: RecyclerMetrics) => {
            this.recyclerMetrics = recyclerMetrics;
            this._changeDetectorRef.markForCheck();
            this.isLoading = false;
        });

        // Load ApexCharts
        this.loadApexCharts();
    }

    // -----------------------------------------------------------------------------------------------------
    /** Attach SVG fill fixer to all ApexCharts */
    // -----------------------------------------------------------------------------------------------------
    loadApexCharts() {
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                },
            },
        };
    }

    // -----------------------------------------------------------------------------------------------------
    /** When the report period is clicked */
    // -----------------------------------------------------------------------------------------------------
    onReportPeriodClicked(reportPeriod: reportPeriod): void {
        // If the custom range is selected, do nothing
        if (reportPeriod.id === 5) {
            this.creatCustomDateRange();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    /** Applies the report period */
    // -----------------------------------------------------------------------------------------------------
    applyReportPeriod(): void {
        this.startDate = null;
        this.endDate = null;
        this.reportPeriod = this.reportPeriods.find(period => period.id === +this.selectedReportPeriodId);

        // If the custom range is selected, do nothing
        if (+this.selectedReportPeriodId === 5) {
            this.creatCustomDateRange();
            return;
        }

        const today = new Date();

        // Determine the start and end dates based on the selected report period
        switch (+this.selectedReportPeriodId) {
            case 1:
                this.startDate = new Date(today.getFullYear(), 0, 1).toISOString();
                break;
            case 2:
                this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).toISOString();
                break;
            case 3:
                this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90).toISOString();
                break;
            case 4:
                this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 270).toISOString();
                break;
        }

        // end is always today plus one day
        this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString();

        // Prepare the observables for the forkJoin
        this.filterDateRange(this.startDate, this.endDate);
    }

    openModal(template: TemplateRef<void>) {
        
      }

    // -----------------------------------------------------------------------------------------------------
    /** Creates a custom date range */
    // -----------------------------------------------------------------------------------------------------
    creatCustomDateRange() {
       const modalRef = this._customDateRangeDialogService.open();
       modalRef.content.dateRangeSelected.subscribe((dateRange: any) => {
        //this.dateRange = dateRange;
        console.log('Selected Date Range:', dateRange);
        if (dateRange) {
            this.isLoading = true;
            this._changeDetectorRef.markForCheck();
            this.startDate = dateRange[0].toISOString();
            this.endDate = dateRange[1].toISOString();
            this.filterDateRange(this.startDate, this.endDate);
        }
      });


    }

    // -----------------------------------------------------------------------------------------------------
    /** Refresh the metrics */
    // -----------------------------------------------------------------------------------------------------
    refreshMetrics(): void {
        if (this.reportPeriod.id !== 5) {
            this.filterDateRange(this.startDate, this.endDate);
            return;
        }

        this.creatCustomDateRange();
    }

    // -----------------------------------------------------------------------------------------------------
    /** Filter the date range */
    // -----------------------------------------------------------------------------------------------------
    filterDateRange(startDate: string, endDate: string) {
        this.isLoading = true;
        // Prepare the observables for the forkJoin
        const observables = [
            this._dashboardService.getMetricsHistory(this.isFoodServiceModel, this.recyclerMetrics.customerId, startDate, endDate),
            this._dashboardService.getRecyclerMetrics(this.isFoodServiceModel, this.recyclerMetrics.customerId, startDate, endDate),
            this._recyclerLocationService.getRecyclerLocationMetrics(this.isFoodServiceModel, +this.recyclerMetrics.customerId, startDate, endDate)
        ];

        // Execute all requests concurrently and handle the results
        forkJoin(observables).subscribe({
            next: ([metricsHistory, recyclerMetrics]) => {
                // Handle the results from all the observables
                console.log('Metrics History:', metricsHistory);
                console.log('Recycler Metrics:', recyclerMetrics);
            },
            error: (error) => {
                this.isLoading = false;
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // Before destroying the component, this function ensures that:
    // 1. All active subscriptions related to '_unsubscribeAll' are terminated to prevent memory leaks.
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._dashboardService.metricsHistory = null;
        this._recyclerLocationService.recyclerLocationMetrics = [];
        this._recyclerLocationService.recyclerLocations = [];
    }

    // -----------------------------------------------------------------------------------------------------
    // Fix the SVG fill references. This fix must be applied to all ApexCharts
    // charts in order to fix 'black color on gradient fills on certain browsers'
    // issue caused by the '<base>' tag.
    // -----------------------------------------------------------------------------------------------------
    private _fixSvgFill(element: Element): void {
        // Current URL
        const currentURL = this._router.url;

        // 1. Select elements with a 'fill' attribute within the target element
        // 2. Retain only those with cross-references using 'url(#id)' syntax
        // 3. Prepend the 'currentURL' to the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').includes('url('))
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }

    // Grouping by month and year
    groupByMonthAndYear(metricsHistory: MetricsHistoryContainer): Record<string, {
        recycledCount: number;
        availableCount: number;
        damagedCount: number;
        ineligibleCount: number;
    }> {
        return metricsHistory.metrics.reduce((grouped, metric) => {
            // Extract year and month from timestampUTC
            const date = new Date(metric.timestampUTC);
            const yearMonthKey = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}`; // YYYY-MM

            if (!grouped[yearMonthKey]) {
                grouped[yearMonthKey] = {recycledCount: 0, availableCount: 0, damagedCount: 0, ineligibleCount: 0};
            }

            // Sum recycledCount and availableCount
            grouped[yearMonthKey].recycledCount += metric.recycledCount;
            grouped[yearMonthKey].availableCount += metric.availableCount;
            grouped[yearMonthKey].damagedCount += metric.damagedCount;
            grouped[yearMonthKey].ineligibleCount += metric.ineligibleCount;

            return grouped;
        }, {} as Record<string, {
            recycledCount: number;
            availableCount: number;
            damagedCount: number;
            ineligibleCount: number;
        }>);
    }

    // Helper function to get abbreviated month name
    getMonthNameFromDate(date: Date): string {
        return date.toLocaleString('default', {month: 'short'}); // 'short' gives the abbreviated month name (e.g., "Nov")
    }

    // -----------------------------------------------------------------------------------------------------
    // This function prepares the data that will be used to render three different charts:
    // 1. Recycled Devices Trend
    // It formats and structures the data, so it aligns with the ApexChart requirements.
    // -----------------------------------------------------------------------------------------------------
    private _prepareChartData(metricsHistory: MetricsHistoryContainer): void {
        const groupedByMonthAndYear = this.groupByMonthAndYear(metricsHistory);
        this.chartOptions = {
            series: this.addChartSeries(groupedByMonthAndYear),
            chart: {
                type: "bar",
                height: '100%',
                width: '100%',
                toolbar: {
                    show: false,
                },
                background: '#ffffff',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    borderRadius: 2
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: Object.entries(groupedByMonthAndYear)
                    .map(([yearMonthKey, counts]) => {
                        const [year, month] = yearMonthKey.split('-');
                        const monthName = this.getMonthNameFromDate(new Date(parseInt(year), parseInt(month) - 1));
                        return `${monthName} ${year}`
                    }),
                labels: {
                    style: {
                        colors: '#333',  // Darker label color
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#333',  // Darker label color
                    }
                }
            },
            fill: {
                opacity: 1
            },

            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                style: {
                    fontSize: '12px',
                    fontFamily: undefined
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                x: {
                    show: true,
                    format: 'dd MMM',
                    formatter: undefined,
                },
                y: {
                    formatter: function (value: number) {
                        return value.toLocaleString('en-US'); // Format the value with commas
                      },
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
                z: {
                    formatter: undefined,
                    title: 'Size: '
                },
                marker: {
                    show: true,
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            }
        };
    }


    private addChartSeries(groupedByMonthAndYear: Record<string, { recycledCount: number; availableCount: number; damagedCount: number; ineligibleCount: number; }>) {
        const seriesData = [
            {
                name: "Returned",
                data: Object.entries(groupedByMonthAndYear)
                    .map(([yearMonthKey, counts]) => counts.recycledCount)
            },
            {
                name: "Damaged",
                data: Object.entries(groupedByMonthAndYear)
                    .map(([yearMonthKey, counts]) => counts.damagedCount)
            },
            {
                name: "Ineligible",
                data: Object.entries(groupedByMonthAndYear)
                    .map(([yearMonthKey, counts]) => counts.ineligibleCount)
            }
        ];

        // Conditionally add the "Available Count" series only if isFoodServiceModel is false
        if (!this.isFoodServiceModel) {
            if (!seriesData.some(series => series.name === "Available")) {
                seriesData.push({
                    name: "Available",
                    data: Object.entries(groupedByMonthAndYear)
                        .map(([yearMonthKey, counts]) => counts.availableCount)
                });
            }
        }
        return seriesData;
    }

    // -----------------------------------------------------------------------------------------------------
    // Determines the recycling state based on the provided rate.
    // Returns: 'poor' (0-40%), 'good' (41-70%), 'excellent' (71-100%), or 'unknown' (outliers).
    // -----------------------------------------------------------------------------------------------------
    getRecyclingState(rate: number): 'poor' | 'good' | 'excellent' | 'unknown' {
        if (rate >= 0 && rate <= 40) {
            return 'poor';
        } else if (rate > 40 && rate <= 70) {
            return 'good';
        } else if (rate > 70 && rate <= 100) {
            return 'excellent';
        } else {
            return 'unknown';
        }
    }
}
