import {inject, Injectable} from '@angular/core';
//import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CustomDateRangeDialogComponent} from "./custom-date-range-dialog.component";
import {BehaviorSubject, Observable} from "rxjs";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Injectable({providedIn: 'root'})
export class CustomDateRangeDialogService {
    private _resetForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    //private _matDialog: MatDialog = inject(MatDialog);
    modalRef?: BsModalRef;
    private modalIsOpen: boolean = false;  
    get resetForm$(): Observable<boolean> {
        return this._resetForm.asObservable();
    }

    set resetForm(value: boolean) {
        this._resetForm.next(value);
    }

    constructor(private modalService: BsModalService) {}


    open(): BsModalRef {
        this.modalIsOpen = true;  // Set flag to true when modal opens
        this.modalRef = this.modalService.show(CustomDateRangeDialogComponent, {
            
            //class: 'max-width: 500px; max-height:400px',         // Optional: Set modal size
            //backdrop: 'static', // Optional: Prevent closing on backdrop click
          });

        // Optionally, reset the flag when the modal is closed
        this.modalRef?.onHidden.subscribe(() => {
            this.modalIsOpen = false;
        });

        return this.modalRef;
    }

    isOpened(): boolean {
        return true;
    }

}
