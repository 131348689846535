
<div [ngClass]="{'hidden': isDialogHidden}">
    <div class="rounded-lg shadow-lg border-0 p-3">
        <div class="border-0 pb-2">
            <span class="modal-title">Custom Date Range</span>
        </div>
        <form (ngSubmit)="onSubmit()" class="mb-0">
            <div class="modal-body pt-0">
                <label class="label-text">Enter a date range*</label>
                <div class="input-group">
                    
                    <input 
                        class="form-control datepicker-text" 
                        type="text" 
                        autocomplete="off"
                        [(ngModel)]="dateRange" 
                        #dRpYMD="bsDaterangepicker" 
                        bsDaterangepicker 
                        #daterangepicker 
                        #field="ngModel" 
                        name="dateRange" 
                        (ngModelChange)="onDateRangeChange($event)"
                        style="cursor: pointer; background-color: #fff;" 
                        [bsConfig]="bsConfig"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary datepicker-icon" (click)="dRpYMD.toggle()" type="button">
                            <i class="fa fa-calendar calendar-icon"></i>
                        </button>
                    </div>
                  </div>
            </div>
            <div class="modal-footer border-0 justify-content-end">
                <button type="button" class="btn btn-secondary rounded-pill px-4" (click)="onCancel()">Cancel</button>
                <button type="submit" class="btn btn-primary rounded-pill px-4" [disabled]="form.invalid">Submit</button>
            </div>
        </form>
    </div>
</div>