import {HttpClientModule, provideHttpClient} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom, isDevMode} from '@angular/core';
import {LuxonDateAdapter} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from '@angular/router';
import {BrowserModule} from "@angular/platform-browser";
import {provideTransloco} from "@ngneat/transloco";
import {provideToastr} from "ngx-toastr";
import {providePlatform} from '@platform';
import {TranslocoHttpLoader} from "./core/transloco/transloco.http-loader";
import {provideAuth} from 'app/core/auth/auth.provider';
import {provideIcons} from 'app/core/icons/icons.provider';
import {appRoutes} from 'app/app.routes';
import {mockApiServices} from 'app/mock-api';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {environment} from "../environments/environment";
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule.withConfig({disableAnimations: false}),
            NgxMaterialTimepickerModule
        ),
        BsModalService,
        ModalModule,
        provideAnimations(),
        provideAnimationsAsync(),
        // provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({scrollPositionRestoration: 'enabled'})),
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: ['en', 'es'],
                defaultLang: 'en',
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader
        }),
        // Platform
        provideAuth(),
        provideIcons(),
        providePlatform({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            platform: {
                layout: 'empty',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        provideToastr(),
        provideAnimations()
    ]
};
